import React, { useState } from "react";
import emailjs from "emailjs-com";
import './DemoForm.css';

const DemoForm = () => {
  // State for form fields
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [currentRole, setCurrentRole] = useState("");
  const [currentRoleOther, setCurrentRoleOther] = useState(""); // State for Current Role Other
  const [improvement, setImprovement] = useState("");
  const [messageSent, setMessageSent] = useState(false);
  const [error, setError] = useState(""); // State for error messages

  // Handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();

    const templateParams = {
      name,
      email,
      currentRole: currentRole === "other" ? currentRoleOther : currentRole,
      improvement,
    };

    emailjs.send("service_ps8p84q", "template_zf9brn4", templateParams, "cCHn3C4Ssji1mivvI")
      .then((response) => {
        console.log("Email sent successfully:", response);
        setMessageSent(true);
        setError(""); // Clear error message if successful
        // Reset form fields after successful submission
        setName("");
        setEmail("");
        setCurrentRole("");
        setCurrentRoleOther("");
        setImprovement("");
      })
      .catch((error) => {
        console.error("Error sending email:", error);
        setError("There was an error sending your message. Please try again."); // Set error message
      });
  };

  return (
    <div>
      <form className="form-container" onSubmit={handleSubmit}>
        <div className="trw">
          <div className="form-group">
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              id="name"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="currentRole">What is your current role:</label>
          <select
            id="currentRole"
            value={currentRole}
            onChange={(e) => {
              setCurrentRole(e.target.value);
              if (e.target.value !== "other") {
                setCurrentRoleOther(""); // Clear the otherRole field if not "Other"
              }
            }}
          >
            <option value="">Select a role</option>
            <option value="owner">Spa Owner</option>
            <option value="operator">Spa Operator</option>
            <option value="consultant">Spa Consultant</option>
            <option value="other">Other</option>
          </select>
        </div>
        {currentRole === "other" && (
          <div className="form-group">
            <label htmlFor="currentRoleOther">Current role Other:</label>
            <input
              type="text"
              id="currentRoleOther"
              value={currentRoleOther}
              onChange={(e) => setCurrentRoleOther(e.target.value)}
              placeholder="Other Role"
            />
          </div>
        )}
        <div className="form-group">
          <label htmlFor="improvement">What are you looking to improve by using SpaTX:</label>
          <select
            id="improvement"
            value={improvement}
            onChange={(e) => setImprovement(e.target.value)}
          >
            <option value="">Select an option</option>
            <option value="skills">Make better spa sales</option>
            <option value="knowledge">Improve my spa operation effectiveness</option>
            <option value="network">Have accurate spa reports and insights</option>
          </select>
        </div>
        <div className="subbtn">
          <button type="submit" className="sb">Submit</button>
        </div>
      </form>
      {messageSent && <p className="scmsg">Thank you for your submission!</p>}
      {error && <p className="error-msg">{error}</p>}
    </div>
  );
};

export default DemoForm;
