import React from 'react';
import './RequestADemo.css';
import DemoForm from '../components/DemoForm';
const RequestADemo = () => {
  return (
    <>
      <div className="requestdemo page-id-03">
        <div className="demorwo01">
        <div className="container rqdemo">
            <h1>Request A Demo</h1>
            <p>This can be the Request a Demo page as well.</p>
            <p className="bluetext">Connect with us today!</p>
          </div>
        </div>
        <div className="homerow4 pricingrow2">
        <div className="bgrow4">
          <div className="container row4text">
            <h2>Request a Demo</h2>
            <div className="demofrm">
            <DemoForm />
            </div>
        </div>
        </div>
      </div>
      </div>
    </>
  )
}

export default RequestADemo
