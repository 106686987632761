import React from "react";
import Slider from "react-slick";
import "./Homeproductslider.css";
import "./Hometestimonials.css";
const Hometestimonials = () => {
  const settings = {
    dots: false,
    autoplay: true,
    autoplaySpeed: 6000,
    infinite: true,
    speed: 800,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    adaptiveHeight: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="slider-container">
      <Slider {...settings}>
        <div className="slide testimonailsbox">
          <div className="ipad">
            <div className="testimgblock">
              <div className="quote">
                <img src="/img/quote.png" alt="quote" />
              </div>
              <div className="testlogo">
                <img src="/img/test-logo.png" alt="testimonial_logo" />
              </div>
            </div>
            <div className="testcontent">
            <div className="cuh">
              <p>
                The passion and knowledge Faheem has, coupled with the
                flexibility of a great team is exciting and should enable SpaTX
                technology to provide an effective and affordable tool to any
                operator or groups within the beauty, spa and wellness industry.
              </p>
              </div>
              <p className="tauthorname">Andrew Gibson</p>
              <p className="authodesignation">
                Strategic Advisor <br />
                Well-being & Hospitality Industry
              </p>
            </div>
          </div>
        </div>
        <div className="slide testimonailsbox">
          <div className="ipad">
            <div className="testimgblock">
              <div className="quote">
                <img src="/img/quote.png" alt="quote" />
              </div>
              <div className="testlogo">
                <img src="/img/test-logo.png" alt="testimonial_logo" />
              </div>
            </div>
            <div className="testcontent">
            <div className="cuh">
              <p>
                Faheem is a great professional especially when it comes to solve
                operational problems efficiently. I always value his opinion and
                follow his advice that are detailed and clear to understand. No
                wonder SpaTX technology has been thought and built to bring
                wellness into a successful new era, thanks to Faheem’s extended
                experiences in the Spa industry.
              </p></div>
              <p className="tauthorname">Sonia Medjane</p>
              <p className="authodesignation">
                General Manager<br></br>
                Wave senses Trading Spa & Fitness 
                Director - The Ritz Carlton Shenzhen
              </p>
            </div>
          </div>
        </div>
        <div className="slide testimonailsbox">
          <div className="ipad">
            <div className="testimgblock">
              <div className="quote">
                <img src="/img/quote.png" alt="quote" />
              </div>
              <div className="testlogo">
                <img src="/img/test-logo.png" alt="testimonial_logo" />
              </div>
            </div>
            <div className="testcontent">
            <div className="cuh">
              <p>
                The team at SpaTX are operators first and technology based
                second. This means they are able to clearly understand the pain
                points of our operations. The solution targets this so well. We
                particularly love how knowledge is built in. Our teams up-sell
                more with little effort. There is a clear ROI with this!
              </p> </div>
              <p className="tauthorname">Orachat Phasook</p>
              <p className="authodesignation">
                Training & Operations Manager <br></br>
                Joali Maldives YWellness
              </p>
            </div>
          </div>
        </div>
      </Slider>
    </div>
  );
};

export default Hometestimonials;
