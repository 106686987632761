import React from "react";
import { useNavigate } from "react-router-dom";
import "./Pricing.css";
const Pricing = () => {
  const navigate = useNavigate(); // Initialize useNavigate hook
    const demobtn = () => {
      navigate("/request-a-demo"); // Navigate to the desired route
    };
    /* 
    const upgradebtn1 = () => {
      window.location.href = "https://buy.stripe.com/9AQ7sTfWw89PdxufYY";
    }; */
    const upgradebtn2 = () => {
      window.location.href = "https://buy.stripe.com/dR614vcKkdu92SQeUV";
    };
    const upgradebtn3 = () => {
      navigate("/request-a-demo"); // Navigate to the desired route
    };
  return (
    <>
      <div className="pricing page-id-03">
        <div className="pricingrow01">
          <div className="container row3text">
            <h2>SELL QUICKER AND MAKE BETTER DECISIONS</h2>
            <button className="btn2" onClick={demobtn}>
              Request a Demo
            </button>
          </div>
        </div>
        <div className="homerow4 pricingrow2">
        <div className="bgrow4">
          <div className="container row4text">
            <h2>Choose Your Plan</h2>
            <div className="priceboxes01">
              { /*
              <div className="pricb1">
                <h3>LITE</h3>
                <p class="pr">US$20 per month</p>
                <p className="pde">Includes 2 users</p>
                <button className="btn3" onClick={upgradebtn1}>Upgrade</button>
                <ul className="list01">
                  <li>Extra cost per user US$5 per month</li>
                  <li>5 x feature ticks</li>
                </ul>
              </div> */}
              <div className="pricb1 priceb2">
                <h3>GROW</h3>
                <p class="pr">US$960 per year</p>
                <p className="pde">Includes 5 users</p>
                <button className="btn3" onClick={upgradebtn2}>Upgrade</button>
                <ul className="list01">
                  <li>Extra cost per user US$5 per month</li>
                  <li>5 x feature ticks</li>
                </ul>
              </div>
              <div className="pricb1">
                <h3>GROUP</h3>
                <p class="pr">Contact Us for a consulation</p>
                <p className="pde pde0" style={{ opacity: 0 }}>Includes 2 users</p>
                <button className="btn3" onClick={upgradebtn3}>Upgrade</button>
                <ul className="list01">
                  <li>Schedule a consultation call</li>
                  <li>5 x feature ticks</li>
                </ul>
              </div>
            </div>
        </div>
        </div>
      </div>
      <div className="homrrow7 pricingrow3">
        <div className="container">
          <div className="scbox">
            <div className="scle1">
            </div>
            <div className="rig">
              <h2>Schedule a <br></br>
              <span>demo today!</span></h2>
              <p>See spa-tx in action and sign up for a personalized demo with one of our technical experts.</p>
              <button className="btn2" onClick={demobtn}>Request a Demo</button>
            </div>
          </div>
        </div>
      </div>
      </div>
    </>
  );
};

export default Pricing;
